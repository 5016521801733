/* Conteneur principal pour utiliser toute la largeur de la page */
.company-overview-container {
    width: 100%;
    max-width: 100%; /* Assure que le conteneur utilise toute la largeur disponible */
    margin: 0 auto;
    padding: 0; /* Suppression du padding pour utiliser toute la largeur */
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    box-sizing: border-box;
  }
  
  /* En-tête de la section de présentation */
  .header-section {
    text-align: center;
    padding: 60px 20px;
    background-color: #ffffff; /* Bandeau blanc pour l'en-tête */
    border-bottom: 1px solid #e0e0e0; /* Bordure pour délimiter la section */
  }
  
  .company-title {
    font-size: 48px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .company-tagline {
    font-size: 24px;
    font-weight: 300;
    color: #555;
  }
  
  /* Section principale du contenu */
  .content-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 60px 20px;
    border: 1px solid #e9e9e9; /* Bordure pour délimiter la section */
    background-color: #fbfeff; /* Bandeau gris clair pour la section de contenu */
  }
  
  .image-section {
    flex: 1;
    margin-right: 30px;
    min-width: 300px;
  }
  
  .office-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .text-section {
    flex: 2;
    min-width: 300px;
  }
  
  .section-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 30px;
  }
  
  .text-section p {
    color: #555;
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  /* Section des statistiques */
  .stats-section {
    display: flex;
    justify-content: space-between;
    padding: 60px 20px;
    background-color: #ffffff; /* Bandeau blanc pour la section des statistiques */
    border-bottom: 1px solid #e0e0e0; /* Bordure pour délimiter la section */
  }
  
  .stat-item {
    text-align: center;
    flex: 1;
    padding: 30px;
    margin: 0 15px;
    border: 1px solid #e0e0e0;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .stat-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .stat-item h3 {
    font-size: 36px;
    color: #007bff;
    margin: 0;
  }
  
  .stat-item p {
    margin: 0;
    color: #333;
  }
  

  .mission-section p {
    color: #555;
    font-size: 18px;
  }
  
  /* Section de l'appel à l'action (CTA) */
  .cta-section {
    text-align: center;
    padding: 60px 20px;
    background-color: #ffffff; /* Bandeau blanc pour la section CTA */
  }
  
  .cta-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .cta-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  

  /* Section de la mission */
.mission-section {
    text-align: center;
    padding: 60px 20px;
    background-color: #fbfeff; /* Bandeau gris clair pour la section de mission */
    border: 1px solid #e9e9e9; /* Bordure pour délimiter la section */
  }
  
  .section-title {
    font-size: 32px;
    color: #333;
    margin-bottom: 40px; /* Espacement sous le titre de section */
  }
  
  .mission-points {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Distribution équitable des points */
    gap: 30px; /* Espacement entre les points */
  }
  
  .mission-point {
    flex: 1;
    max-width: 300px; /* Largeur maximale pour les points de mission */
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .mission-icon {
    font-size: 40px; /* Taille des icônes */
    color: #007bff; /* Couleur bleue pour les icônes */
    margin-bottom: 20px; /* Espacement sous les icônes */
  }
  
  .mission-point h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px; /* Espacement sous le titre */
  }
  
  .mission-point p {
    color: #555;
    font-size: 18px;
    margin: 0;
  }

  /* Styles responsive pour les petits écrans */
@media (max-width: 768px) {
  /* Section principale du contenu */
  .content-section {
      flex-direction: column; /* Empile les éléments verticalement */
      align-items: center; /* Centre les éléments horizontalement */
      padding: 30px 10px; /* Réduit le padding pour les petits écrans */
  }

  .image-section {
      margin-right: 0; /* Supprime l'espace à droite */
      margin-bottom: 20px; /* Ajoute un espace en bas de l'image */
      min-width: 100%; /* Utilise toute la largeur disponible */
  }

  .text-section {
      min-width: 100%; /* Utilise toute la largeur disponible */
  }

  .section-title {
      font-size: 24px; /* Réduit la taille du titre pour les petits écrans */
      margin-bottom: 20px; /* Réduit l'espacement sous le titre */
  }

  .text-section p {
      font-size: 16px; /* Réduit la taille du texte */
      margin-bottom: 20px; /* Réduit l'espacement en bas du paragraphe */
  }

  /* Section des statistiques */
  .stats-section {
      flex-direction: column; /* Empile les statistiques verticalement */
      padding: 30px 10px; /* Réduit le padding pour les petits écrans */
  }

  .stat-item {
      margin: 10px 0; /* Ajoute un espace en haut et en bas des éléments de statistiques */
      padding: 20px; /* Réduit le padding à l'intérieur des éléments de statistiques */
      width: 100%; /* Utilise toute la largeur disponible */
  }

  .stat-item h3 {
      font-size: 28px; /* Réduit la taille des titres des statistiques */
  }

  .stat-item p {
      font-size: 14px; /* Réduit la taille du texte des statistiques */
  }

  /* Section de la mission */
  .mission-section {
      padding: 30px 10px; /* Réduit le padding pour les petits écrans */
  }

  .section-title {
      font-size: 24px; /* Réduit la taille du titre pour les petits écrans */
      margin-bottom: 20px; /* Réduit l'espacement sous le titre de section */
  }

  .mission-points {
      flex-direction: column; /* Empile les points de mission verticalement */
      gap: 20px; /* Réduit l'espacement entre les points de mission */
  }

  .mission-point {
      max-width: 100%; /* Utilise toute la largeur disponible */
  }

  .mission-icon {
      font-size: 30px; /* Réduit la taille des icônes */
      margin-bottom: 15px; /* Réduit l'espacement sous les icônes */
  }

  .mission-point h3 {
      font-size: 20px; /* Réduit la taille du titre des points de mission */
      margin-bottom: 10px; /* Réduit l'espacement sous le titre */
  }

  .mission-point p {
      font-size: 16px; /* Réduit la taille du texte des points de mission */
  }
}

/* Styles responsive pour les très petits écrans */
@media (max-width: 480px) {
  .company-title {
      font-size: 36px; /* Réduit encore la taille du titre */
  }

  .company-tagline {
      font-size: 18px; /* Réduit encore la taille du sous-titre */
  }

  .section-title {
      font-size: 20px; /* Réduit encore la taille du titre pour les très petits écrans */
  }

  .text-section p {
      font-size: 14px; /* Réduit encore la taille du texte */
  }

  .stat-item h3 {
      font-size: 24px; /* Réduit encore la taille des titres des statistiques */
  }

  .stat-item p {
      font-size: 12px; /* Réduit encore la taille du texte des statistiques */
  }

  .mission-icon {
      font-size: 24px; /* Réduit encore la taille des icônes */
  }

  .mission-point h3 {
      font-size: 18px; /* Réduit encore la taille du titre des points de mission */
  }

  .mission-point p {
      font-size: 14px; /* Réduit encore la taille du texte des points de mission */
  }
}
