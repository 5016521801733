/* Styles pour la page de service */
.service-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 20px;
}

.service-section {
    background: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-title {
    color: #007BFF; /* Couleur du titre */
    font-size: 2.5em; /* Taille du titre */
    margin-bottom: 10px;
    text-align: center; /* Centrer le titre */
}

.service-intro {
    font-size: 1.2em; /* Taille du texte d’introduction */
    font-weight: bold; /* Mettre le texte en gras */
    text-align: center; /* Centrer le texte d’introduction */
    margin-bottom: 20px;
}

/* New grid layout for the content */
.service-content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns for image and text */
    align-items: center;
    gap: 20px; /* Space between grid items */
    margin-top: 10px;
}

/* Styling the service image */
.service-image {
    width: 100%;
    height: 700px; /* Adjust this to maintain aspect ratio */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow for better presentation */
}

/* Details section on the right */
.service-details {
    max-width: 600px; /* Largeur maximale pour le texte */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left; /* Align text to the left */
    margin-bottom: 10px ;
}

.service-details p {
    font-size: 16px; /* Taille du texte */
    margin-bottom: 10px; /* Reduced spacing between paragraph and list */
}

.service-details ul {
    list-style-type: none; /* Supprimer les puces par défaut */
    padding: 0; /* Supprimer le padding par défaut de la liste */
    margin-top: 0; /* Ensure there's no space between <p> and <ul> */
}

.service-details ul li {
    font-size: 18px; /* Taille agrandie du texte des éléments de la liste */
    margin-bottom: 10px; /* Adjusted spacing between list items */
    position: relative; /* Nécessaire pour le positionnement des puces personnalisées */
    padding-left: 1.5em; /* Espace pour les puces personnalisées */
}

.service-details ul li:before {
    content: "•";
    color: #007BFF; /* Couleur des puces */
    font-weight: bold;
    display: inline-block;
    width: 1em;
    position: absolute;
    left: 0; /* Positionner les puces directement à gauche du texte */
    top: 0; /* Aligner les puces avec le texte */
}

.features {
    margin-top: 10px; /* Ajoute un espace en haut */
    font-weight: bold; /* Met le texte en gras */
}

.features-list {
    margin-left: 0; /* Supprime la marge gauche */
    margin-right: auto; /* Centre la liste */
}

/* Responsive design for smaller screens */
/* Styles responsive pour les petits écrans */
@media (max-width: 768px) {
    /* Ajustement de la grille de contenu */
    .service-content-grid {
        grid-template-columns: 1fr; /* Empile les éléments image et texte */
        gap: 10px; /* Réduit l'espace entre les éléments */
    }

    /* Ajustement de l'image de service */
    .service-image {
        height: auto; /* Ajuste la hauteur pour garder les proportions de l'image */
        margin-bottom: 20px; /* Ajoute un espace en bas pour séparer l'image du texte */
    }

    /* Détails de service centrés pour petits écrans */
    .service-details {
        max-width: 100%; /* Utilise la largeur complète disponible */
        text-align: center; /* Centre le texte */
        padding: 0 10px; /* Réduit le padding pour les petits écrans */
    }

    .service-details p {
        font-size: 14px; /* Réduit la taille du texte */
    }

    .service-details ul li {
        font-size: 16px; /* Réduit la taille du texte des éléments de la liste */
        margin-bottom: 8px; /* Réduit l'espacement entre les éléments de la liste */
        padding-left: 1em; /* Ajuste l'espace pour les puces personnalisées */
    }

    .service-details ul li:before {
        font-size: 1em; /* Réduit la taille des puces */
    }

    .features {
        margin-left: 0; /* Supprime la marge gauche */
        margin-right: auto; /* Centre le texte */
        margin-top: 10px; /* Ajoute un espace en haut */
        font-weight: bold; /* Met le texte en gras */
    }

    .features-list {
        margin-left: 0; /* Supprime la marge gauche */
        margin-right: auto; /* Centre la liste */
    }
}
