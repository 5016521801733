/* Styles pour la page de service */
.service-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 20px;
}

.service-section {
    background: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-title {
    color: #007BFF; /* Couleur du titre */
    font-size: 2.5em; /* Taille du titre */
    margin-bottom: 10px;
    text-align: center; /* Centrer le titre */
}

.service-intro {
    font-size: 1.2em; /* Taille du texte d’introduction */
    font-weight: bold; /* Mettre le texte en gras */
    text-align: center; /* Centrer le texte d’introduction */
    margin-bottom: 20px;
}

.service-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px; /* Espace entre l'image et le texte */
    flex-direction: row; /* Assure que l'image et le texte sont côte à côte */
}

.service-image {
    width: 50%; /* Taille de l'image par rapport à la largeur de la page */
    height: auto; /* Garder les proportions de l'image */
    border-radius: 8px;
}

.service-details {
    max-width: 600px; /* Largeur maximale pour le texte */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrer le texte et les listes */
    text-align: center; /* Centrer le texte */
}

.service-details p {
    font-size: 16px; /* Taille du texte */

}

.service-details ul {
    list-style-type: none; /* Supprimer les puces par défaut */
    padding: 0; /* Supprimer le padding par défaut de la liste */
    margin-top: 150px;

}

.service-details ul li {
    font-size: 18px; /* Taille agrandie du texte des éléments de la liste */
    margin-bottom: 15px; /* Réduire l'espacement entre les éléments de la liste */
    position: relative; /* Nécessaire pour le positionnement des puces personnalisées */
    padding-left: 1.5em; /* Espace pour les puces personnalisées */
}

.service-details ul li:before {
    content: "•";
    color: #007BFF; /* Couleur des puces */
    font-weight: bold;
    display: inline-block;
    width: 1em;
    position: absolute;
    left: 0; /* Positionner les puces directement à gauche du texte */
    top: 0; /* Aligner les puces avec le texte */
}


/* Spécifique à HCMS */
.service-image-hcms {
    width: 30%; /* Réduit la taille de l'image à 30% pour cette page */
}

.service-details h3{
    margin-right: 90px; 
    position: absolute;
    margin-top: 100px;
}

/* Styles responsive */
@media (max-width: 768px) {
    .service-title {
        font-size: 2em; /* Réduire la taille du titre */
    }

    .service-intro {
        font-size: 1em; /* Réduire la taille du texte d’introduction */
    }

    .service-content {
        flex-direction: column; /* Passer à une disposition en colonne */
        align-items: center; /* Centrer les éléments */
    }

    .service-image {
        width: 100%; /* Utiliser la largeur complète disponible */
        height: auto; /* Garder les proportions de l'image */
        margin-bottom: 20px; /* Ajouter un espace en bas */
    }

    .service-details {
        max-width: 100%; /* Utiliser la largeur complète disponible */
        padding: 20px; /* Ajuster le padding */
        text-align: center; /* Centrer le texte */
    }

    .service-details p {
        font-size: 14px; /* Réduire la taille du texte */
    }

    .service-details ul {
        margin-top: 20px; /* Réduire l'espace en haut */
    }

    .service-details ul li {
        font-size: 16px; /* Réduire la taille du texte des éléments de la liste */
        padding-left: 1em; /* Ajuster l'espace pour les puces personnalisées */
    }

    .service-details ul li:before {
        font-size: 1em; /* Réduire la taille des puces */
    }

    .service-image-hcms {
        width: 100%; /* Utiliser la largeur complète disponible */
        margin-bottom: 20px; /* Ajouter un espace en bas */
    }
}