/* Styles pour la page des services overview */
.services-overview-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(500px, 1fr)); /* Deux colonnes avec une largeur minimale */
    gap: 50px;
    padding: 20px;
    max-width: 1500px; /* Largeur maximale pour la grille */
    margin: 0 auto; /* Centre la grille horizontalement */
    margin-bottom: 100px;
}

.services-overview-container {
    position: relative; /* Nécessaire pour positionner le texte absolument par rapport au conteneur */
}

.services-overview-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 500px;
    width: 100%; /* Assure que la carte utilise toute la largeur disponible dans la cellule de la grille */
    max-width: 900px; /* Largeur maximale pour chaque carte */
    margin: 0 auto; /* Centre chaque carte dans sa cellule de la grille */
    position: relative; /* Nécessaire pour positionner le texte par rapport à la carte */
}

.services-overview-image {
    width: 100%;
    height: 500px; /* Hauteur fixe pour l'image */
    object-fit: cover;
    border-bottom: 1px solid #ddd;
}

.services-overview-text {
    padding: 15px;
    text-align: center;
    background: #f9f9f9; /* Fond légèrement différent pour le texte */
    border: 1px solid #ddd; /* Bordure autour du cadre de texte */
    border-top: none; /* Enlève la bordure du haut pour une transition plus douce */
    width: 80%; /* Réduit la largeur du texte par rapport à la carte */
    max-width: 90%; /* Largeur maximale pour le texte */
    position: absolute; /* Positionne le texte absolument par rapport au conteneur */
    bottom: -30px; /* Positionne le texte légèrement au-dessus du bas de la carte */
    left: 10%; /* Centre le texte horizontalement par rapport au conteneur */
    box-sizing: border-box; /* Inclut les bordures dans la largeur totale */
    border-radius: 8px; /* Arrondir les coins du texte */
    z-index: 1; /* Assure que le texte est au-dessus de la carte */
}

.services-overview-title {
    font-size: 1.2em; /* Réduit la taille du titre */
    color: #007BFF; /* Couleur du titre */
    margin-bottom: 10px;
}

.services-overview-description {
    font-size: 0.9em; /* Réduit la taille de la description */
    color: #333;
    line-height: 1.6;
}

.services-overview-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    color: #fff;
    text-decoration: none; /* Supprimer le soulignement du texte */
    border-radius: 5px;
    font-size: 1em;
    background-color: #007BFF; /* Couleur de fond bleue par défaut */
    transition: background-color 0.3s ease;
}

.services-overview-button:hover {
    background-color: #0056b3; /* Couleur de fond bleue plus foncée au survol */
}

/* Ajouter une classe pour un bouton de couleur verte si nécessaire */
.services-overview-button.green {
    background-color: #28a745; /* Couleur de fond verte */
}

.services-overview-button.green:hover {
    background-color: #218838; /* Couleur de fond verte plus foncée au survol */
}

/* Styles pour la dernière carte */
.last-card {
    margin-bottom: 100px; /* Espace en bas de la dernière carte */
    display: flex;
    margin-left: 0px;
    justify-content: center; /* Centre la carte horizontalement */
}



/* Responsive design pour les petits écrans */
@media (max-width: 768px) {
    /* Ajustement de la grille des services */
    .services-overview-grid {
        grid-template-columns: 1fr; /* Une seule colonne pour empiler les cartes */
        gap: 20px; /* Réduit l'espace entre les cartes */
        padding: 10px; /* Réduit le padding autour de la grille */
    }

    /* Ajustement des cartes de service */
    .services-overview-card {
        height: auto; /* Ajuste la hauteur pour le contenu dynamique */
        max-width: 100%; /* Utilise la largeur complète disponible */
    }

    /* Ajustement des images dans les cartes */
    .services-overview-image {
        height: 300px; /* Réduit la hauteur de l'image */
    }

    /* Ajustement du texte dans les cartes */
    .services-overview-text {
        width: 100%; /* Utilise toute la largeur disponible */
        bottom: -10px; /* Ajuste la position du texte */
        left: 0; /* Aligne le texte à gauche */
        padding: 10px; /* Réduit le padding */
    }

    .services-overview-title {
        font-size: 1em; /* Réduit la taille du titre */
    }

    .services-overview-description {
        font-size: 0.8em; /* Réduit la taille de la description */
    }

    .services-overview-button {
        padding: 8px 15px; /* Réduit le padding du bouton */
        font-size: 0.9em; /* Réduit la taille du texte du bouton */
    }
}

@media (max-width: 480px) {
    /* Ajustements supplémentaires pour les très petits écrans */
    .services-overview-image {
        height: 200px; /* Réduit encore plus la hauteur de l'image */
    }

    .services-overview-title {
        font-size: 0.9em; /* Réduit encore la taille du titre */
    }

    .services-overview-description {
        font-size: 0.7em; /* Réduit encore la taille de la description */
    }

    .services-overview-button {
        padding: 6px 12px; /* Réduit encore le padding du bouton */
        font-size: 0.8em; /* Réduit encore la taille du texte du bouton */
    }
}
