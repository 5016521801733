/* Styles pour la barre de navigation avec logo agrandi */

.header {
  /* background-color: #f0f0f0; */
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Ensure dropdown is positioned correctly */
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav ul li {
  margin-right: 30px;
  position: relative; /* Ensure the dropdown appears relative to the nav item */
}

.nav ul li:last-child {
  margin-right: 0;
}

.nav ul li a {
  text-decoration: none;
  color: #333;
  padding: 12px 16px;
}

.nav ul li.active {
  background-color: #ddd;
}

.nav ul li a:hover {
  background-color: #ddd;
}

.logo {
  height: 60px;
  width: auto;
}


@media (max-width: 768px) {
  .header {
    padding: 10px 0; /* Réduire le padding en haut et en bas */
  }

  .nav {
    padding: 0 15px; /* Réduire le padding des côtés */
  }

  .nav ul {
    padding: 0; /* Retirer le padding des éléments de menu */
  }

  .nav ul li {
    margin-right: 10px; /* Réduire la marge entre les éléments */
    font-size: 14px; /* Réduire la taille de la police */
    padding: 10px 5px; /* Réduire le padding des éléments de menu */
  }

  .nav ul li a {
    padding: 0px 5px; /* Ajuster le padding des liens */
  }

  .logo {
    height: 40px; /* Réduire la taille du logo */
  }

  /* Optionnel : Ajuster la taille du fond pour le menu hamburger */
  .menu-hamburger {
    margin-top: 10px; /* Ajuster le margin-top si nécessaire */
  }
}

