/* Styles de base pour le composant AfricaBanner */
.africa-banner {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: auto; /* Utiliser auto pour les hauteurs variables */
  box-sizing: border-box; /* Inclure le padding dans la largeur totale */
  flex-wrap: wrap; /* Permet de passer à la ligne si nécessaire */
}

.africa-text {
  flex: 1;
  padding-right: 20px;
  min-width: 300px; /* Largeur minimale pour éviter que le texte devienne trop étroit */
}

.africa-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333; /* Couleur sombre pour le texte */
}

.africa-text p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666; /* Couleur plus claire pour le texte */
}

.africa-image {
  flex: 1;
  text-align: right;
  height: auto; /* Hauteur automatique pour l'image */
  min-width: 300px; /* Largeur minimale pour l'image */
}

.africa-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* Bordures arrondies pour un look moderne */
}

/* Media queries pour les écrans plus petits */
@media (max-width: 768px) {
  .africa-banner {
    flex-direction: column; /* Empiler le texte et l'image verticalement */
    text-align: center; /* Centrer le texte et l'image */
  }
  
  .africa-text {
    padding-right: 0;
    padding-bottom: 20px; /* Ajouter un espace sous le texte */
    min-width: 100%; /* Assurer que le texte prend toute la largeur */
  }
  
  .africa-image {
    text-align: center; /* Centrer l'image */
    min-width: 100%; /* Assurer que l'image prend toute la largeur */
  }
  
  .africa-image img {
    border-radius: 0; /* Supprimer les bordures arrondies pour les petits écrans si besoin */
  }
}
