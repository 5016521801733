.experience-container {
  /* Ajoutez ici des styles globaux si nécessaire */
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: rgb(228, 228, 228);
  font-size: 3rem;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.tab-banner {
  background-image: url("../../components/assets/experience.jpg");
  background-size: cover;
  background-position: center;
  height: 200px;
  margin-bottom: 10px;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.tab-buttons button {
  background: transparent;
  border: 2px solid #333;
  color: #333;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tab-buttons button.active {
  background-color: #333;
  color: #fff;
}


.clients-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.client-card {
  position: relative;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  flex: 1 1 auto; /* Permet au conteneur de s'adapter à la taille disponible */
  max-width: 800px; /* Limite la largeur maximale du conteneur */
  margin: 0 auto; /* Centre le conteneur horizontalement */
}


.client-logo {
  width: 100%; /* Réduit la largeur de l'image à la largeur du conteneur parent */
  height: auto; /* Maintient les proportions de l'image */
  margin: 10px 0; /* Espacement vertical autour de l'image */
  margin-bottom: 90px;
}


.case-studies-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.case-study-card,
.certificate-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.certificates-text {
  flex: 1;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  /* text-align: left; */
  padding: 10px;
  margin-top: 10px;

}

.certificates-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.certificates-list li {
  margin-bottom: 15px; /* Espacement accru entre chaque élément de la liste */
}


.certificate-logo {
  max-width: 100%;
  height: auto;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.certificates-section {
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.certificates-content {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .certificates-content {
    flex-direction: column;
    align-items: center;
  }

  .certificates-text,
  .certificates-image {
    width: 100%;
    text-align: center;
  }
}


/* Responsive Styles */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  .tab-buttons {
    gap: 10px;
  }

  .tab-buttons button {
    font-size: 14px;
    padding: 8px 16px;
  }
  .client-logo {
    margin-bottom: 260px;
  }


}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  .tab-buttons {
    flex-direction: column;
  }

  .tab-buttons button {
    width: 100%;
    padding: 12px;
  }

  .client-logo {
    margin-bottom: 260px;
  }


}
