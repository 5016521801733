/* Bandeau de sélection d'année */
.year-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px 0;
    background-color: #f0f0f0; /* Couleur de fond pour le bandeau */
    border-radius: 8px;
  }
  
  .year-button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .year-button.active {
    background: #0056b3;
  }
  
  .year-button:hover {
    background: #0056b3;
    transform: scale(1.05); /* Agrandissement léger au survol */
  }
  
  /* Réutilisez les styles précédents pour les cartes */
  .news-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .news-title {
    font-size: 36px;
    color: #007bff;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .news-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .news-card-link {
    text-decoration: none;
    color: inherit;
  }
  
  .news-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .news-card-title {
    font-size: 24px;
    margin: 15px;
    color: #222;
  }
  
  .news-card-date {
    font-size: 14px;
    margin: 0 15px 15px;
    color: #888;
  }
  
  .news-card-summary {
    font-size: 16px;
    margin: 0 15px 15px;
    color: #555;
  }
  
  .news-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transform: scale(1.03);
  }
  

  /* Responsive Styles */
@media (max-width: 768px) {
  .news-title {
    font-size: 28px;
  }

  .news-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Réduit la taille minimale des colonnes */
  }

  .news-card-title {
    font-size: 20px;
  }

  .news-card-date {
    font-size: 12px;
  }

  .news-card-summary {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .news-title {
    font-size: 24px;
  }

  .news-grid {
    grid-template-columns: 1fr; /* Affiche les cartes en une seule colonne */
  }

  .news-card-title {
    font-size: 18px;
  }

  .news-card-date {
    font-size: 10px;
  }

  .news-card-summary {
    font-size: 12px;
  }

  .year-button {
    padding: 8px 16px;
    font-size: 14px; /* Réduit la taille du texte des boutons pour les petits écrans */
  }
}