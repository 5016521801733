/* Assurez-vous que le conteneur de la carte prend toute la largeur */
.map-container {
    position: relative;
    width: 100%; /* La carte occupe toute la largeur de l'écran */
    height: 500px; /* Hauteur fixe, ajustez si nécessaire */
    margin-left: 20px; /* Décalage vers la droite */
    margin-top: 20px; /* Décalage vers le haut si nécessaire */
    pointer-events: inherit;
  }
  
  .map-container .rsm-marker {
    cursor: pointer;
  }

  .map-container text {
    pointer-events: none; /* Désactive les événements de pointeur pour les étiquettes */
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-anchor: middle;
  }
  .composable-map {
    cursor: default; /* Assurez-vous que le curseur ne change pas lors du survol de la carte */
  }
  
  .map-container .rsm-marker circle {
    fill: #c71700; /* Couleur de fond des markers */
    stroke: #000; /* Couleur de bordure des markers */
    stroke-width: 1px; /* Épaisseur de la bordure des markers */
  }
  
  .map-container .rsm-marker text {
    font-size: 16px; /* Taille du texte des markers */
    fill: #000000; /* Couleur du texte des markers */
  }

  /* Pour les petits écrans (max-width: 576px) */
@media (max-width: 576px) {
  .map-container {
    height: 300px; /* Réduit la hauteur de la carte pour les petits écrans */
    margin-left: 0; /* Supprime le décalage vers la droite */
    margin-top: 10px; /* Réduit le décalage vers le haut */
  }

  .map-container .rsm-marker text {
    font-size: 12px; /* Réduit la taille du texte des markers */
  }
}

  