.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  bottom: 0;
}

.footer-links {
  margin-bottom: 20px;
}

.footer-nav {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center; /* Alignement horizontal au centre */
}

.footer-nav li {
  margin-right: 20px;
}

.footer-nav li:last-child {
  margin-right: 0; /* Supprimer la marge à droite du dernier élément */
}

.footer-nav li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px; /* Taille de police légèrement augmentée */
}

.social-icons {
  margin-top: 10px;
  display: flex;
  justify-content: center; /* Alignement horizontal au centre */
}

.social-icon {
  width: 30px;
  height: auto;
  margin: 0 10px; /* Ajout de marges autour des logos */
  filter: invert(1); /* Inversion des couleurs pour tous les logos */
}

/* Styling pour le texte de copyright */
.copyright {
  margin-top: 20px;
  font-size: 12px;
  font-style: italic; /* Texte en italique */
  color: #999; /* Couleur de texte plus claire */
}
