.contact-page {
    display: flex;
    justify-content: center;
    padding: 50px 20px; /* Ajout de padding pour éviter que les contenus ne touchent les bords */
    margin-bottom: 60px;
  }
  
  .contact-container {
    width: 600px; /* Ajustement de la largeur des conteneurs */
    padding: 30px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 20px; /* Espace à droite du conteneur de formulaire */
  }
  
  .info-container {
    width: 450px; /* Gardez cette largeur si elle convient */
    padding: 30px;
    display: flex;
    flex-direction: column; /* Les éléments s'empilent verticalement */
    justify-content: center; /* Centrage vertical si nécessaire */
    align-items: center; /* Centrage horizontal */
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 10px; /* Espacement avec le formulaire */
    height: 610px; /* Gardez la hauteur si elle convient */
  }

  

  .contact-container h2,
  .info-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form {
    margin-top: 20px;
  }
  
  .contact-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #45a049;
    box-shadow: 0 0 5px rgba(69, 160, 73, 0.5);
    outline: none;
  }
  
  .contact-form textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .contact-form button {
    width: 100%;
    padding: 12px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #333333;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 15px;
    font-size: 0.9em;
  }
  
  .info-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .info-container ul li {
    margin-bottom: 20px;
    font-size: 1em;
  }
  
  .info-container ul li strong {
    margin-right: 10px;
  }
  

  /* Contact.css */

.success-message {
  color: green;
  margin-top: 50px;
  text-align: center;
}

.error-message {
  color: red;
  margin-top: 50px;
  text-align: center;
}

.map-container {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  margin-right:15px;
  /* align-items: center; */
}
.map-container iframe {
  border-radius: 8px; /* Ajout d'un léger arrondi */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ajout d'une ombre pour un meilleur rendu */
}


/* Styles généraux pour les petits écrans */
@media (max-width: 768px) {
  .contact-page {
    padding: 20px 10px; /* Réduire le padding */
    flex-direction: column; /* Alignement en colonne pour petits écrans */
  }
  
  .contact-container,
  .info-container {
    width: 100%; /* Utiliser la largeur complète de l'écran */
    margin: 0; /* Enlever les marges latérales */
  }
  
  .contact-container {
    padding: 20px; /* Réduire le padding */
    margin-bottom: 20px; /* Ajouter un espace en bas */
  }
  
  .info-container {
    padding: 20px; /* Réduire le padding */
  }
  
  .contact-container h2,
  .info-container h2 {
    font-size: 1.5em; /* Réduire la taille de la police */
    margin-bottom: 15px; /* Réduire la marge en bas */
  }
  
  .contact-form .form-group {
    margin-bottom: 15px; /* Réduire l'espacement entre les groupes */
  }
  
  .contact-form label {
    margin-bottom: 6px; /* Réduire la marge inférieure */
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px; /* Réduire le padding */
    font-size: 0.9em; /* Réduire la taille de la police */
  }
  
  .contact-form textarea {
    min-height: 80px; /* Réduire la hauteur minimale */
  }
  
  .contact-form button {
    padding: 10px; /* Réduire le padding */
    font-size: 0.9em; /* Réduire la taille de la police */
  }
  
  .info-container ul li {
    margin-bottom: 15px; /* Réduire l'espacement entre les éléments */
  }
  
  .info-container ul li strong {
    font-size: 0.9em; /* Réduire la taille de la police */
  }
  
  .success-message,
  .error-message {
    margin-top: 30px; /* Réduire la marge en haut */
    font-size: 0.9em; /* Réduire la taille de la police */
  }
}
